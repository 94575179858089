import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {Subscription} from "rxjs";
import ModeleConditionnementsPlatConditionnementsVariantesDTO
  from "../../../../core/dtos/conditionnement/modele-conditionnement-plat-conditionnement-variante-dto";
import ResteDTO from "../../../../core/dtos/conditionnement/reste-dto";
import {ConviveDTO} from "../../../../core/dtos/convive-dto";
import {ActivatedRoute} from "@angular/router";
import {RestesService} from "../../../../core/services/conditionnements/restes.service";
import ModeleConditionnementPlc__PrestationDTO
  from "../../../../core/dtos/conditionnement/plc/conditionnement/prestation/mc-plc-prestation-dto";
import McPlcPrestation__McPlc__McpCvDTO
  from "../../../../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-prestation-mc-plc-mc-cv-dto";
import McPlc__McpCvDTO from "../../../../core/dtos/conditionnement/plc/conditionnement/plat/mc-plc-mc-cv-dto";
import {DATAGRID_ROW_TYPES} from "../../../../core/services/technique/devextreme.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../../core/constants";
import {cloneDeep as _cloneDeep} from 'lodash';
import {
  ModelePlcParametrage,
  ModelesPlcService
} from "../../../../core/services/conditionnements/plc/modeles-plc.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'yo-mp-parametrage-plc',
  templateUrl: './mp-parametrage.component.html',
  styleUrls: ['./mp-parametrage.component.scss']
})
export class ParametrageConditionnementModelesPlcComponent implements OnInit, OnDestroy {

  subRoute: Subscription;

  mcPlcMcpCVList: McPlc__McpCvDTO[];
  mcplcPrestations: ModeleConditionnementPlc__PrestationDTO[];
  mcpConditionnementVarianteList: ModeleConditionnementsPlatConditionnementsVariantesDTO[];
  mcPlcPrestaMcPlcCVList: McPlcPrestation__McPlc__McpCvDTO[];

  /**
   * Totalité des prestations
   */
  prestationList: ConviveDTO[] = [];

  /**
   * Gestion des queues
   */
  queuesConfiguration: any = {};

  /**
   * Totalité des restes
   */
  resteList: ResteDTO[] = [{id: 1} as ResteDTO];

  /**
   *  Un trompe oeil pour DevExtreme pour avoir 1 footer d'une seule ligne.
   */
  footerList: any[] = [{id: 1}];

  /**
   * Mapping entre le n° de colonne DevExtreme et l'identifiant d'une prestation
   */
  mappingColumnsPrestations: Map<number, number> = new Map<number, number>();
  gridParametrage: any[];
  gridParametrageToSave: any[] = [];
  hasIDistri = false;
  idMcPlc: number;

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-modeles-plc-parametrage';

  @ViewChild("grid") grid: DxDataGridComponent;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private resteSvc: RestesService,
              private mcPlcSvc: ModelesPlcService,
              private toastSvc: ToastService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initRestes();
    this.initDataFromSupplierSubscription();
  }

  initDataFromSupplierSubscription = () => {
    this.subRoute = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlcSupplier) {

          this.mcpConditionnementVarianteList = data.modelePlcSupplier.mcpConditionnementVariantesList;
          this.mcplcPrestations = data.modelePlcSupplier.mcplcPrestations;
          this.mcPlcPrestaMcPlcCVList = data.modelePlcSupplier.mcPlcPrestaMcPlcCVList;
          this.mcPlcMcpCVList = data.modelePlcSupplier.mcPlcMcpCVList;
          this.idMcPlc = data.modelePlcSupplier.idModeleConditionnementPlc;

          if (this.mcplcPrestations) {
            this.prestationList = this.mcplcPrestations
              .map(model => model.prestation).sort((a, b) => a.libelle.localeCompare(b.libelle))
          }

          this.initGridParametrage();
        }
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  openCreateRowParametrage = (): void => {
    this.mcPlcSvc.announceCreationRowParametrageAsked();
  };

  getToolTipPrestation = cell => cell.row.data.prestations[cell.columnIndex][cell.row.data.id].actif ?
    'Cliquez ici pour désactiver la prestation' : 'Cliquez ici pour activer la prestation';

  /**
   * Lors d'un click sur une cellule
   * Si la cellule est desactivé, on verifie si il existe bien une quantite définie pour pouvoir la reactiver
   * @param event
   */
  onCellClick = async (event: any) => {
  const {actif, mcplc__mcpCvid, mcplc__prestaid} = event.data.prestations[event.columnIndex][event.data.id];

    if (event.columnIndex > 2 && !this.gridParametrageToSave.find(item => item.id == event.data.id)) {
      const item: any = Object.assign({}, event.data);
      delete item.prestations;
      item.data = [];
      this.gridParametrageToSave.push(item);
    }

    let cellUpdateEnabled: boolean = true;
    if (actif === false) {
      const response = await this.mcPlcSvc.enableActivationCell(mcplc__mcpCvid, mcplc__prestaid).toPromise();
      cellUpdateEnabled = response.resultList[0]
      if (cellUpdateEnabled === false)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, "Ce conditionnement / variante est nul au sein de ce modèle de plat, veuillez renseigner une quantité au préalable");
    }

    // 0 = MCP, 1 = Conditionnement, 2 = Variante
    if (event.columnIndex > 2 && cellUpdateEnabled === true) {
      event.data.prestations[event.columnIndex][event.data.id].actif = !actif;
      this.cellActiveBackground(event);
    }
  };


  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.lineHeight = '40px';
    }
  };

  onCellPrepared = (event: any) => {
    this.cellActiveBackground(event);
  };

  cellActiveBackground = (event: any) => {
    // 0 = MCP, 1 = Conditionnement, 2 = Variante
    if (event.columnIndex > 2 && event.rowType === DATAGRID_ROW_TYPES.DATA) {
      this.gridParametrageToSave.forEach(item => {
        if (item.id == event.data.id) {
          const {
            actif,
            mcplc__mcpCvid,
            mcplc__prestaid,
            mcPlcPrestaMcPlcMcpCvId
          } = event.data.prestations[event.columnIndex][event.data.id];
          if (item.data.filter(i => i.mcplc__mcpCvid == mcplc__mcpCvid && i.mcplc__prestaid == mcplc__prestaid
            && i.mcPlcPrestaMcPlcMcpCvId == mcPlcPrestaMcPlcMcpCvId && i.idPrestation == event.columnIndex).length > 0) {
            item.data.find(i => i.mcplc__mcpCvid == mcplc__mcpCvid && i.mcplc__prestaid == mcplc__prestaid
              && i.mcPlcPrestaMcPlcMcpCvId == mcPlcPrestaMcPlcMcpCvId && i.idPrestation == event.columnIndex).actif = actif;
          } else {
            item.data.push({
              actif,
              mcplc__mcpCvid,
              mcplc__prestaid,
              mcPlcPrestaMcPlcMcpCvId,
              idPrestation: event.columnIndex
            });
          }
        }
      });
      const cell = event.data.prestations[event.columnIndex][event.data.id];
      if (cell) {
        event.cellElement.style.cursor = 'pointer';
        if (cell.actif) {
          event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
        } else {
          event.cellElement.style.backgroundColor = UI_COLORS.NOT_CHECKED;
        }
      }
    }
  };

  private initRestes = () => {
    this.resteSvc.getAll()
      .subscribe(restes => this.resteList = restes.resultList);
  };

  private initGridParametrage = () => {
    if (this.mcpConditionnementVarianteList) {
      this.gridParametrage = this.mcpConditionnementVarianteList.map(mcv => {
        const current = this.mcPlcMcpCVList.find((m: McPlc__McpCvDTO) => {
          return m.mcpCv && m.mcpCv.id === mcv.id
        });

        const prestations = {};

        this.prestationList = this.prestationList.filter(prestation => this.mcPlcPrestaMcPlcCVList.find((m: McPlcPrestation__McPlc__McpCvDTO) =>
          m.mcPlcMcpCv &&
          m.mcPlcMcpCv.mcpCv &&
          m.mcPlcMcpCv.mcpCv.id === mcv.id &&
          m.modeleConditionnementPlcPrestation.prestation &&
          m.modeleConditionnementPlcPrestation.prestation.id === prestation.id));

        this.prestationList.forEach((prestation, idx) => {
          const currentMcPlcPrestaMcPlcCV = this.mcPlcPrestaMcPlcCVList.find((m: McPlcPrestation__McPlc__McpCvDTO) =>
            m.mcPlcMcpCv &&
            m.mcPlcMcpCv.mcpCv &&
            m.mcPlcMcpCv.mcpCv.id === mcv.id &&
            m.modeleConditionnementPlcPrestation.prestation &&
            m.modeleConditionnementPlcPrestation.prestation.id === prestation.id
          );

          // + 3 car les premières colonnes sont mcp, conditionnement et variante :
          if (currentMcPlcPrestaMcPlcCV) {
            const key = idx + 3;
            this.mappingColumnsPrestations.set(key, prestation.id);
            // prestations => objet dont la clé est l'association de l'identifiant d'une mcp condition variante et d'une prestation.
            // En effet, chaque valeur prescrite dans une cellule est faite pour une prestation d'une mcp condition variante.
            prestations[key] = {
              [mcv.id]: { // identifiant pour représenter la ligne de l'ihm
                mcplc__prestaid: currentMcPlcPrestaMcPlcCV.modeleConditionnementPlcPrestation.id,
                mcplc__mcpCvid: currentMcPlcPrestaMcPlcCV.mcPlcMcpCv.id,
                mcPlcPrestaMcPlcMcpCvId: currentMcPlcPrestaMcPlcCV.id, // Identifiant à utiliser pour le backend
                actif: currentMcPlcPrestaMcPlcCV.actif ? currentMcPlcPrestaMcPlcCV.actif : false
              }
            }
          }
        });

        const result = {
          id: mcv.id,
          mcpLabel: mcv.modeleConditionnementPlat ? mcv.modeleConditionnementPlat.libelle : '',
          conditionnementLabel: mcv.conditionnementVariante && mcv.conditionnementVariante.conditionnement ? mcv.conditionnementVariante.conditionnement.libelle : '',
          varianteLabel: mcv.conditionnementVariante && mcv.conditionnementVariante.variante ? mcv.conditionnementVariante.variante.libelle : 'Aucune',
          idmcPlcMcpCV: current.id,
          prestations
        };

        return (result);
      });
    }
  };

  /**
   * Enregistre les valeurs paramétrées de la grille.
   */
  save = (): void => {
    const grid = _cloneDeep(this.gridParametrageToSave);
    this.gridParametrageToSave = [];
    const data: ModelePlcParametrage = {grid, idMcplc: this.idMcPlc};
    this.mcPlcSvc.saveParametrage(data)
      .subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Paramétrage réalisé avec succès');
      });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Paramétrage des modèles points de livraison`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

  collapseGrid = () => {
    this.grid.instance.collapseAll();
  };

  expendGrid = () => {
    this.grid.instance.expandAll();
  };

  isResizableElementInGrid = () => this.prestationList && this.prestationList.length > 0;
}
