import {ContratMenuConviveDecoupageDTO} from "./contratmenuconvivedecoupage-dto";
import {ObjectDTO} from "./object-dto";
import {ContratMenuConvive__ModeleNutriDTO} from "./contratmenuconvive__modele-nutri-dto";

export class ContratMenuConviveRepasDTO extends ObjectDTO {

  //    contrat menu convive repas
  prixRepasTheorique: number;
  prixRepasVente: number;
  idJourSemaine: number;
  effectifPrevu: number;
  actif: boolean;

  // contrat menu convive
  idContratMenuConvive: number;


  // cmc modele nutri
  idCmcMn: number;
  cmcMnDateDebut: Date;
  cmcMnDateFin: Date;
  contratMenuConvive__modeleNutri: ContratMenuConvive__ModeleNutriDTO;

  //repas
  repasId: number;
  repasCode: string;
  repasLibelle: string;
  repasOrdre: number;
  repasActif: boolean;

  //contrats menus convives decoupages
  contratMenuConviveDecoupageDTOList: ContratMenuConviveDecoupageDTO[] =[];


}
