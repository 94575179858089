import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/index';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../dtos/contratmenuconvive__contrainte-alim-dto';
import {UtilsService} from '../../utils/utils.service';
import {cloneDeep as _cloneDeep} from 'lodash';
import * as moment from 'moment';
import {Auth2Service} from '../security/auth2.service';
import {DUPLICATION_ECRASER_MENUS} from '../../constants';
import {HttpService} from '../technique/http.service';
import {ContratMenuConviveDTO} from "../../dtos/contratmenuconvive-dto";

@Injectable({
  providedIn: 'root'
})
export class DuplicationMenuService {

  private subjectDisplayDialogDuplicationMenus = new Subject();
  displayDialogDuplicationMenus$ = this.subjectDisplayDialogDuplicationMenus.asObservable();

  private subjectMonthChange = new Subject();
  monthChange$ = this.subjectMonthChange.asObservable();


  constructor(private httpSvc: HttpService, private utils: UtilsService, private auth2Svc: Auth2Service) {

  }

  announceDisplayDialogDuplicationMenus = (displayDialog: boolean) => {
    this.subjectDisplayDialogDuplicationMenus.next(displayDialog);
  };


  prepareDialogDuplicationMenus = () => this.httpSvc.get(`dolrest/gestionmenus2/prepare-dialog-duplication-menus`);

  /**
   * Lancer la duplication des menus par prestation/regime
   *
   * @param sourceDates date de debut et date de fin de la periode de la prestion source
   * @param targetDateDebut
   * @param sourceCmcCa
   * @param targetCmcCaList
   * @param ecraserMenus  Si vrai, alors on ecrase les menus déjà existants de la cible
   * @param recopieRegimeSource
   * @param recopieDeclinaisonSource
   * @param recopieTauxPriseMenuSource
   */
  launchDuplicationMenus(sourceDates: Date[], targetDateDebut: Date, sourceCmcCa: ContratMenuConvive__ContrainteAlimDTO,
                         targetCmcCaList: ContratMenuConvive__ContrainteAlimDTO[], ecraserMenus: DUPLICATION_ECRASER_MENUS,
                         recopieRegimeSource: boolean, recopieDeclinaisonSource: boolean, recopieTauxPriseMenuSource: boolean) {

    const fd = new FormData();
    fd.set('sourceDates', sourceDates.map(date => this.utils.getYYYYMMDD(moment(date))).join(','));
    fd.set('targetDateDebut', this.utils.getYYYYMMDD(moment(targetDateDebut)));
    fd.set('sourceCmcCaId', sourceCmcCa.id + '');
    fd.set('targetCmcCaIds', targetCmcCaList.map(cmcCa => cmcCa.id).join(','));
    fd.set('ecraserMenus', ecraserMenus);
    fd.set('recopieRegimeSource', recopieRegimeSource ? 'true' : 'false');
    fd.set('recopieDeclinaisonSource', recopieDeclinaisonSource ? 'true' : 'false');
    fd.set('recopieTauxPriseMenuSource', recopieTauxPriseMenuSource ? 'true' : 'false');

    return this.httpSvc.post(`dolrest/gestionmenus2/launch-duplication-menus`, fd);
  }

  /**
   * Lancer la duplication des menus par prestation
   *
   * @param sourceDates date de debut et date de fin de la periode de la prestion source
   * @param targetDateDebut
   * @param sourceCmc
   * @param targetCmcList
   * @param ecraserMenus  Si vrai, alors on ecrase les menus déjà existants de la cible
   * @param recopieRegimeSource
   * @param recopieDeclinaisonSource
   * @param recopieTauxPriseMenuSource
   */
  launchDuplicationMenusByPrestation(sourceDates: Date[], targetDateDebut: Date, sourceCmc: ContratMenuConviveDTO,
                         targetCmcList: ContratMenuConviveDTO[], ecraserMenus: DUPLICATION_ECRASER_MENUS,
                         recopieRegimeSource: boolean, recopieDeclinaisonSource: boolean, recopieTauxPriseMenuSource: boolean) {

    const fd = new FormData();
    fd.set('sourceDates', sourceDates.map(date => this.utils.getYYYYMMDD(moment(date))).join(','));
    fd.set('targetDateDebut', this.utils.getYYYYMMDD(moment(targetDateDebut)));
    fd.set('sourceCmcId', sourceCmc.id + '');
    fd.set('targetCmcIds', targetCmcList.map(cmc => cmc.id).join(','));
    fd.set('ecraserMenus', ecraserMenus);
    fd.set('recopieRegimeSource', recopieRegimeSource ? 'true' : 'false');
    fd.set('recopieDeclinaisonSource', recopieDeclinaisonSource ? 'true' : 'false');
    fd.set('recopieTauxPriseMenuSource', recopieTauxPriseMenuSource ? 'true' : 'false');

    return this.httpSvc.post(`dolrest/gestionmenus2/launch-duplication-menus-by-prestation`, fd);
  }

  /**
   * N'activer que le jour semaine de dateToAccept
   * @param dateToAccept
   */
  getDisabledDateDebutDays(dateToAccept: Date) {

    let disabledDays: number[] = [];
    let isoWeekDay = moment(dateToAccept).isoWeekday();

    // i = 1 = Lundi
    for (let i = 1; i <= 7; i++) {

      // le dimanche est le 0 dans les disabledDays, contrainte p-calendar kjlnhjk
      if (isoWeekDay != i) {
        if (i === 7) {
          disabledDays.push(0);
        } else {
          disabledDays.push(i);
        }
      }

    }


    return disabledDays;
  }

  getPeriodeADupliquerLabel(selectedRangeSourceDates: Date[]) {

    return `Du ${this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(selectedRangeSourceDates[0]))} au ${this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(selectedRangeSourceDates[1]))}`;

  }

  getJourDebutPrestationCibles(selectedDateDebutTarget: Date) {
    return this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(selectedDateDebutTarget));
  }

  announceMonthChange($event: any) {
    this.subjectMonthChange.next($event);
  }
}

