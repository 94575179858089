import {ObjectDTO} from "./object-dto";
import {PointDeLivraisonDTO} from "./point-de-livraison-d-t-o";
import {ContratMenuConviveRepasDTO} from "./contratmenuconviverepas-dto";
import {RegimeAlimentaireDTO} from "./regime-alimentaire-dto";

export class RepasDTO  extends ObjectDTO{

  id: number;
  code: string;
  libelle: string;
  ordre: number;
  actif: boolean;

  pointLivraison: PointDeLivraisonDTO;

  contratMenuConviveRepasList: ContratMenuConviveRepasDTO[];

  regimesAlimentaire: RegimeAlimentaireDTO[] = [];
}
