<div *ngIf="!utils.isCollectionNullOrEmpty(cmcdPlcList)" @triggerPeriode>


  <!--    GRILLE DES EFFECTIFS-->

  <p-table
    [scrollHeight]="utils.scrollHeightContent(340)"
    styleClass="p-datatable-sm"
    [scrollable]="true"
    [frozenWidth]="(colRepasWidth*5)+'px'"
    [value]="rows">

    <!--CAPTION-->
    <ng-template pTemplate="caption">
      <div class="d-flex justify-content-between">

        <div class="font-12">
          <i class="fas fa-map-pin mg-r-5 pn-border-color"></i>
          <span class="mg-r-5">
                {{ pointDeLivraison.libelle | uppercase }}
            - <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(cmcMnSvc.getPeriodeLabelHTML(periode))"></span>
              </span>
          <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        </div>

        <div>
          <span class="mg-r-5">
            <p-menu appendTo="body" #editPlanning [popup]="true" [model]="itemsEditPlanning"></p-menu>
            <yo-button
              iconClass="fas fa-edit"
              class="mg-r-5"
              (onClick)="editPlanning.toggle($event)"
              tooltip="Modification en masse des  effectifs, coûts et taux de prise théoriques">
            </yo-button>
          </span>
          <span class="mg-r-5">
            <yo-button
              tooltip="Enregistrer"
              type="submit"
              iconClass="fas fa-save"
              buttonClass="cta-success"
              (onClick)="savePlanning()"
              [disabled]="!gds.canModify(contratMenuConvive)"></yo-button>
          </span>
        </div>

      </div>
    </ng-template>

    <!--FROZEN COLUMNS HEADER-->
    <ng-template pTemplate="frozenheader">
      <tr>
        <th [style.width.px]="colRepasWidth">Jour</th>
        <th [style.width.px]="colRepasWidth">Repas</th>
        <th [style.width.px]="colRepasWidth">Régime</th>
        <th [style.width.px]="colRepasWidth" title="Effectif prévisionnel">
          Effectif prév.
        </th>
        <th [style.width.px]="colRepasWidth" title="Coût prévisionnel">
          Coût prév.
        </th>
      </tr>
    </ng-template>

    <!--FROZEN COLUMNS BODY-->
    <ng-template pTemplate="frozenbody" let-rowData let-rowIndex="rowIndex">
      <tr>
        <td [style.width.px]="colRepasWidth"
            [style.vertical-align]="'middle'"
            *ngIf="rowGroupMetadata[rowData.idJourSemaine].index === rowIndex"
            [attr.rowspan]="rowGroupMetadata[rowData.idJourSemaine].size">
          <div class="no-overflow">
            <strong>{{ utils.getJourSemaineById(rowData.idJourSemaine).viewValue | uppercase }}</strong>
          </div>
        </td>
        <td [style.width.px]="colRepasWidth" [style.vertical-align]="'middle'"
            [style.height.px]="getTailleComposante(rowData)">
          <div class="no-overflow"
               [title]="rowData.repasLibelle">
            <strong>{{ rowData.repasLibelle }}</strong>
          </div>
        </td>

        <td [style.width.px]="colRepasWidth*3" [style.vertical-align]="'middle'"
            [style.height.px]="getTailleComposante(rowData)">
          <ng-container *ngTemplateOutlet="tplRegime;context:{list:getAllCmcrCmcCaPlc(rowData)}"></ng-container>
        </td>

      </tr>
    </ng-template>


    <!--HEADER-->
    <ng-template pTemplate="header">

      <tr>
        <ng-container *ngFor="let col of cols ">
          <th [style.width.px]="cellWidth">
            <div class="no-overflow" [title]="col.libelle">
              {{ col.libelle | uppercase }}
            </div>

          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!--BODY-->
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr>

        <!--CELLULES-->
        <ng-container *ngFor="let col of cols ">
          <ng-container *ngTemplateOutlet="tplCell;context:{cmcr:rowData,cell:getCell(rowData,col)}">
          </ng-container>
        </ng-container>

      </tr>

    </ng-template>

  </p-table>

</div>


<!--TEMPLATE CELLULE-->
<ng-template #tplCell let-cell="cell" let-cmcr="cmcr">
  <td [style.width.px]="cellWidth" style="text-align: right;"
      [style.height.px]="getTailleComposante(cmcr)"
      [style.vertical-align]="'top'">


    <ng-container *ngFor="let slot of utils.getSlots(cell.nombreChoixMaximum); let i=index; ">

      <ng-container
        *ngTemplateOutlet="tplCmcdPlc;context:{cmcdPlc:getCmcdPlc(cell, i+1)}">
      </ng-container>

    </ng-container>


  </td>
</ng-template>

<!--TEMPLATE contrat_menu_convive_decoupage__plc INPUT CELL (saisie de l'effectif previsionnel en % pour un jour/repas/decoupage/plat)-->
<ng-template #tplCmcdPlc let-cmcdPlc="cmcdPlc">

  <ng-container *ngIf="!utils.isNullOrEmpty(cmcdPlc)">

    <div class="input-group input-group-sm mg-t-5">
      <div class="input-group-prepend ">
        <span class="input-group-text font-10">Plat {{ cmcdPlc.ordre }}</span>
      </div>
      <input type="text" [style.max-width.px]="50" style="text-align: right;"
             placeholder=""
             title="Taux de prise"

             (keydown)="utils.keydownNumber($event,cmcdPlc,'tauxDePrise',true)"
             [(ngModel)]="cmcdPlc.tauxDePrise"
             pKeyFilter="pint">

      <div class="input-group-append">
          <span class="input-group-text font-10">%
          </span>
      </div>

      <ng-container *ngIf="cmcdPlc.arrondiEffPrev">
        <i class="fas fa-exclamation-triangle danger-color cursor mg-l-5 mg-t-5"
           title="Arrondi réalisé pour que la somme des plats corresponde à l'effectif prévisionnel du jour / repas ."
        ></i>
      </ng-container>

    </div>

  </ng-container>


</ng-template>


<!--TEMPLATE REGIME -->
<ng-template #tplRegime let-list="list">
  <tr *ngFor="let item of list">
    <td [style.width.px]="colRepasWidth" [style.vertical-align]="'middle'">
      <div [title]="item.libelleRegime">
        <strong>{{ item.libelleRegime }}</strong>
      </div>
    </td>
    <td [style.width.px]="colRepasWidth" [style.vertical-align]="'middle'">
      <div class="input-group input-group-sm mg-t-5">
        <input type="text" [style.max-width.px]="50" style="text-align: right;"
               placeholder=""
               (keydown)="utils.keydownNumber($event, item,'effectifPrevisionnel',true)"
               [(ngModel)]="item.effectifPrevisionnel"
               pKeyFilter="pint">
        <div class="input-group-append">
            <span class="input-group-text font-10">pers.
            </span>
        </div>
      </div>
    </td>

    <td [style.width.px]="colRepasWidth" [style.vertical-align]="'middle'">
      <div class="input-group input-group-sm mg-t-5">
        <input type="text" [style.max-width.px]="50" style="text-align: right;"
               placeholder=""
               (keydown)="utils.keydownNumber($event,item,'coutPrevisionnel',true)"
               [(ngModel)]="item.coutPrevisionnel"
               pKeyFilter="pint">

        <div class="input-group-append">
          <span class="input-group-text font-10">€
          </span>
        </div>

      </div>
    </td>
  </tr>
</ng-template>


<!--DIALOG EFFECTIFS PREV-->
<dx-popup
  [showTitle]="true"
  [title]="'Modifier les effectifs prévisionnels'"
  [(visible)]="displayEffectifDialog"
  [width]="800"
  [height]="'auto'"
  [maxHeight]="700"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  [style]="{'overflow':'visible'}"
  (onHidden)="closeDialogEffectifPrev()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="dx-field mt-3">
        <div>Jours ouvrés :</div>
        <dx-tag-box
          #tagBoxJoursSemaineEff
          [items]="joursSemaine"
          [showSelectionControls]="true"
          displayExpr="viewValue"
          (onValueChanged)="onChangeJoursSemaine($event)"
        >
        </dx-tag-box>
      </div>
    </div>

    <div class="col-md-4">
      <dx-tree-list
        #treeListRepasListEff
        [dataSource]="repasList"
        itemsExpr="regimesAlimentaire"
        dataStructure="tree"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        (onSelectionChanged)="onChangeParentDenreeSelection($event)"
      >
        <dxo-selection
          mode="multiple"
          [recursive]="true">
        </dxo-selection>
        <dxi-column dataField="libelle" caption="Tout sélectionner"></dxi-column>

      </dx-tree-list>
    </div>

    <div class="col-md-4">
      <h3 class="first">Effectif prévisionnel</h3>
      <div class="input-group input-group-sm ">
        <input type="text" style="width: 60px;" [(ngModel)]="majEffectifPrev">
        <div class="input-group-append ">
          <span class="input-group-text">pers.</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <yo-button iconClass="fas fa-check" class="mg-r-5"
                 (onClick)="updateProperty(selectedJoursSemaine, selectedRepasList, selectedRegimeList, majEffectifPrev, 'effectifPrevisionnel')"></yo-button>
    </div>
  </div>

</dx-popup>

<!--DIALOG COUT THEORIQUE-->
<dx-popup
  [showTitle]="true"
  [title]="'Modifier les coûts prévisionnels'"
  [(visible)]="displayCoutDialog"
  [width]="800"
  [height]="'auto'"
  [maxHeight]="700"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  [style]="{'overflow':'visible'}"
  (onHidden)="closeDialogCoutsPrev()"
>
  <div class="row">
    <div class="col-md-4">
      <div class="dx-field mt-3">
        <div>Jours ouvrés :</div>
        <dx-tag-box
          #tagBoxJoursSemaineCout
          [items]="joursSemaine"
          [showSelectionControls]="true"
          displayExpr="viewValue"
          (onValueChanged)="onChangeJoursSemaine($event)"
        >
        </dx-tag-box>
      </div>
    </div>

    <div class="col-md-4">
      <dx-tree-list
        #treeListRepasListCout
        [dataSource]="repasList"
        itemsExpr="regimesAlimentaire"
        dataStructure="tree"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        (onSelectionChanged)="onChangeParentDenreeSelection($event)"
      >
        <dxo-selection
          mode="multiple"
          [recursive]="true">
        </dxo-selection>
        <dxi-column dataField="libelle" caption="Tout sélectionner"></dxi-column>

      </dx-tree-list>
    </div>

    <div class="col-md-4">
      <h3 class="first">Coût Prévisionnel</h3>
      <div class="input-group input-group-sm ">
        <input type="text" style="width: 60px;" [(ngModel)]="majCoutPrev">
        <div class="input-group-append ">
          <span class="input-group-text">€</span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <yo-button iconClass="fas fa-check" class="mg-r-5"
                 (onClick)="updateProperty(selectedJoursSemaine, selectedRepasList, selectedRegimeList, majCoutPrev, 'coutPrevisionnel')"></yo-button>
    </div>
  </div>

</dx-popup>


<!--DIALOG TAUX DE PRISE PREV-->
<p-dialog
  [modal]="true"
  [dismissableMask]="false"
  [style]="{'width':'1200px'}"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="displayTauxDePriseDialog">

  <!--TITRE-->
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      Modifier les taux de prise prévisionnels
    </div>
  </ng-template>


  <!--CONTENT-->
  <div class="row">
    <div class="col-md-2">
      <h3 class="first">Jours ouvrés</h3>
      <p-listbox [options]="joursSemaine" [(ngModel)]="selectedJoursSemaine" multiple="multiple" checkbox="checkbox"
                 optionLabel="viewValue">
      </p-listbox>
    </div>
    <div class="col-md-2">
      <h3 class="first">Repas</h3>
      <p-listbox [options]="repasList" [(ngModel)]="selectedRepasList" multiple="multiple" checkbox="checkbox"
                 optionLabel="libelle">
      </p-listbox>
    </div>

    <div class="col-md-2">
      <h3 class="first">Composantes</h3>
      <p-listbox [options]="decoupageRepasList" [(ngModel)]="selectedDecoupageRepasList" multiple="multiple"
                 checkbox="checkbox"
                 optionLabel="libelle">
      </p-listbox>
    </div>

    <div class="col-md-2">
      <h3 class="first">Plats</h3>
      <p-listbox [options]="platList" [(ngModel)]="selectedPlatList" multiple="multiple" checkbox="checkbox">
      </p-listbox>
    </div>

    <div class="col-md-2">
      <h3 class="first">Taux de prise prévisionnel</h3>
      <div class="input-group input-group-sm ">
        <input type="number" pKeyFilter="pint" style="width: 60px;" [(ngModel)]="majTauxDePrisePrev">
        <div class="input-group-append ">
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>
  </div>


  <!--FOOTER-->
  <ng-template pTemplate="footer">
    <div class="row">
      <div class="col-md-12 text-right">
        <yo-button iconClass="fas fa-check" class="mg-r-5"
                   (onClick)="updateTauxDePrisePrev(selectedJoursSemaine,selectedRepasList,selectedDecoupageRepasList,selectedPlatList,majTauxDePrisePrev)"></yo-button>
        <yo-button iconClass="fas fa-times" (onClick)="displayTauxDePriseDialog=false"
                   buttonClass="cta-delete"></yo-button>
      </div>
    </div>

  </ng-template>


</p-dialog>

