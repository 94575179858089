export class ProduitnomenclatureDTO {

  //produit declinaison décliné
  pdDeclineLibelle: string;
  pdDeclineUdmLibelle: string;
  pdDeclineUdmId: number;
  pdDeclineId: number;
  pdDeclinePrixachat:number;
  pdDeclineRatioUT:number;

  pdDeclineDeclinaisonLibelle: string;
  pdDeclineDeclinaisonId: number;

  pdDeclineProduitLibelle: string;
  pdDeclineProduitId: number;
  pdDeclineTypeProduitId: number;
  pdDeclineTypeProduitFabrique: boolean;

  //produit declinaison utilisé
  pdUtiliseLibelle: string;
  pdUtiliseUdmLibelle: string;
  pdUtiliseUdmId: number;
  pdUtiliseUdmType:UDM_TYPE;
  pdUtiliseId: number;
  pdUtilisePrixachat:number;


  pdUtiliseDeclinaisonLibelle : string;
  pdUtiliseDeclinaisonId : number;

  pdUtiliseProduitLibelle: string;
  pdUtiliseProduitId: number;
  pdUtiliseRatioUniteTechnique:number;
  pdUtiliseRatioVolume:number;
  pdUtiliseTypeProduitId: number;
  pdUtiliseTypeProduitFabrique: boolean;


  // produit de subtitution
  subtitution: boolean;

  pdOriginalLibelle: string;
  pdOriginalUdmLibelle: string;
  pdOriginalUdmId: number;
  pdOriginalUdmType: number;
  pdOriginalId: number;
  pdOriginalDeclinaisonLibelle: string;
  pdOriginalDeclinaisonId: number;
  pdOriginalPrixachat: number;
  pdOriginalRatioUniteTechnique: number;
  pdOriginalRatioVolume: number;

  pdOriginalProduitLibelle: string;
  pdOriginalProduitId: number;
  pdOriginalTypeProduitId: number;
  pdOriginalTypeProduitFabrique: boolean;

  quantiteEnUtOriginal: number;
  quantiteEnKgOriginal: number;

  //produit nomenclature
  id: number;
  actif: boolean;
  ordre: number;
  tauxPerte: number;
  jourDebutUtilisation: number;
  moisDebutUtilisation: number;
  jourFinUtilisation: number;
  moisFinUtilisation: number;
  inclus: boolean;
  quantiteEnUt: number;
  quantiteEnKg: number;
  volumeEnLitre:number;
  conditionneAPart: boolean;

  // transient
  quantiteEnUtTmp: number;
  quantiteEnKgTmp: number;
  volumeEnLitreTmp:number;

  isErreurPrix: boolean;
  messageErreurPrix: string;

  // transient, prix en UT * quantite en UT
  prixUdpUtQuantite:number;

}

export enum UDM_TYPE{
  AUCUN=0,
  MASSE=1,
  VOLUME=2
}
