<dx-popup
  title="Impression d'une édition pour conditionnement"
  [showTitle]="true"
  [height]="'auto'"
  [width]="'700px'"
  (onHiding)="closeDialog()"
  [(visible)]="displayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">
          <strong><i class="fas fa-calendar mg-r-5"></i>SÉLECTION DES JOURS</strong>
        </div>
        <div class="card-body">
          <!--SELECTION TYPE JOUR-->
          <div class="row">
            <div class="col">
              <dx-button-group
                [items]="typesJours"
                keyExpr="value"
                stylingMode="outlined"
                [selectedItemKeys]="['jourCondi']"
                (onItemClick)="changeSelectedJour($event)"
              >
              </dx-button-group>
            </div>
          </div>
          <!--SELECTION JOURS-->
          <div class="row">
            <div class="col">
              <ng-container *ngTemplateOutlet="tplJours"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #tplJours let-form="form">
    <!--quand jour conso sélectionné-->
    <ng-container *ngIf="typeJourSelected ==='jourConso'">
        <dx-list
          #listDatesConso
          [dataSource]="datesConso"
          width="auto"
          [height]="330"
          selectionMode="all"
          selectAllMode="allPages"
          displayExpr="label"
          valueExpr="value"
          [showSelectionControls]="true"
          [searchEnabled]="true"
          (onSelectionChanged)="changeJourObligatoire($event)"
          searchExpr="label"
          searchMode="contains"
        ></dx-list>
    </ng-container>

    <ng-container *ngIf="typeJourSelected ==='jourCondi'">
        <dx-list
          #listDatesCondi
          [dataSource]="datesCondi"
          width="auto"
          [height]="330"
          selectionMode="all"
          selectAllMode="allPages"
          displayExpr="label"
          valueExpr="value"
          [showSelectionControls]="true"
          [searchEnabled]="true"
          (onSelectionChanged)="changeJourObligatoire($event)"
          searchExpr="label"
          searchMode="contains"
        ></dx-list>
    </ng-container>

  </ng-template>

  <div class="dialog-line actif-container">
    <div class="label" title="Ordre">Format d'impression <span class="danger-color">*</span></div>
    <div class="value">
      <dx-radio-group [(value)]="formatPrinting" valueExpr="value" displayExpr="label"
                      [items]="formatPrintingOptions"></dx-radio-group>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <yo-button
      type="submit"
      iconClass="fas fa-print"
      buttonClass="cta-success"
      (onClick)="print()"
      label="Imprimer">
    </yo-button>
    <yo-button
      class="mg-l-5"
      buttonClass="cta-delete"
      (onClick)="closeDialog()"
      iconClass="fas fa-times"
      tooltip="Fermer">
    </yo-button>
  </div>

</dx-popup>

