import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GestioncontratsRoutingModule} from './gestioncontrats-routing.module';
import {GrilleContratsComponent} from './grille-contrats/grille-contrats.component';
import {GestioncontratsRootComponent} from './gestioncontrats-root/gestioncontrats-root.component';
import {SharedModule} from '../shared/shared.module';
import {ContratmenuConviveResolverService} from './contratmenu-convive/contratmenu-convive-resolver.service';
import {CmcFicheidentiteComponent} from './contratmenu-convive/cmc-ficheidentite/cmc-ficheidentite.component';
import {CmcPlanningComponent} from './contratmenu-convive/cmc-planning/cmc-planning.component';
import {CmcEquipesComponent} from './contratmenu-convive/cmc-equipes/cmc-equipes.component';
import {GestiongemrcnModule} from '../gestion-gemrcn/gestiongemrcn.module';
import {ContratmenuConviveComponent} from './contratmenu-convive/contratmenu-convive.component';
import {CmcEquipeDialogEditComponent} from './contratmenu-convive/cmc-equipes/cmc-equipe-dialog-edit/cmc-equipe-dialog-edit.component';
import {CmcEquipesJoursComponent} from './contratmenu-convive/cmc-equipes/cmc-equipes-jours/cmc-equipes-jours.component';
import {CmcPointDeLivraisonComponent} from './contratmenu-convive/cmc-points-de-livraison/cmc-point-de-livraison/cmc-point-de-livraison.component';
import {CmcPointsDeLivraisonComponent} from './contratmenu-convive/cmc-points-de-livraison/cmc-points-de-livraison.component';
import {CmcPointsDeLivraisonGcomComponent} from './contratmenu-convive/cmc-points-de livraison-gcom/cmc-point-de-livraison-gcom.component';
import {GcomPointDeLivraisonService} from '../core/services/gcom/gcom-point-de-livraison.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../core/core.module';
import {DialogContratFormComponent} from './grille-contrats/dialog-contrat-form/dialog-contrat-form.component';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';

import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';
import {CheckboxModule} from 'primeng/checkbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {InputTextModule} from "primeng/inputtext";
import {
    DxCheckBoxModule,
    DxDataGridModule, DxDateBoxModule,
    DxLookupModule,
    DxPopupModule,
    DxScrollViewModule, DxTagBoxModule, DxTextAreaModule,
    DxToolbarModule, DxTreeListModule
} from "devextreme-angular";


@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        GestioncontratsRoutingModule,
        SharedModule,
        GestiongemrcnModule,
        FormsModule,
        ReactiveFormsModule,
        CardModule,
        TableModule,

        OverlayPanelModule,
        DropdownModule,
        CalendarModule,
        MenuModule,
        DialogModule,
        ListboxModule,
        CheckboxModule,
        TabMenuModule,
        InputTextModule,
        DxDataGridModule,
        DxToolbarModule,
        DxPopupModule,
        DxLookupModule,
        DxScrollViewModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxTextAreaModule,
        DxTreeListModule,
        DxTagBoxModule,

    ],
  entryComponents: [
    CmcEquipeDialogEditComponent
  ],
  declarations: [
    GestioncontratsRootComponent,
    GrilleContratsComponent,
    ContratmenuConviveComponent,
    CmcFicheidentiteComponent,
    CmcPlanningComponent,
    CmcEquipesComponent,
    CmcPointsDeLivraisonComponent,
    CmcEquipeDialogEditComponent,
    CmcEquipesJoursComponent,
    CmcPointDeLivraisonComponent,
    CmcPointsDeLivraisonGcomComponent,
    DialogContratFormComponent
  ],
  providers: [
    ContratmenuConviveResolverService,
    GcomPointDeLivraisonService
  ]

})
export class GestioncontratsModule {
}
