// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10956(6ab6aa3e85)-C04/12/2024-08:00:13-B04/12/2024-08:06:05' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10956(6ab6aa3e85)-C04/12/2024-08:00:13-B04/12/2024-08:06:05",
  branch: "master",
  latestTag: "6.1",
  revCount: "10956",
  shortHash: "6ab6aa3e85",
  longHash: "6ab6aa3e85ca86b62397876f763a29fa1915048c",
  dateCommit: "04/12/2024-08:00:13",
  dateBuild: "04/12/2024-08:06:05",
  buildType: "Ansible",
  } ;
